import type { FeatureDefinition, GrowthBook } from "@growthbook/growthbook";
import { nanoid } from "nanoid/non-secure";

import type { Environment } from "~/lib/env";

const USER_ID_KEY = "brale_xyz_uid";

const GROWTHBOOK: { apiKeys: Record<Environment, string> } = {
  apiKeys: {
    dev: "key_dev_165dbbf77c428a4e",
    local: "key_loca_6e0da5c70aef5eae",
    prod: "key_prod_fa0fb514447c742a",
  },
};

type Features = Readonly<{ features: Record<string, FeatureDefinition> }>;

type InitializeReq = Readonly<{
  env: Environment;
  isClient: boolean;
  growthBook: GrowthBook;
}>;

export async function initialize({
  env,
  isClient,
  growthBook,
}: InitializeReq): Promise<void> {
  if (isClient) {
    const id = getOrCreateUserId();
    if (id != null) growthBook.setAttributes({ id });
  }

  const res = await fetch(
    `https://cdn.growthbook.io/api/features/${GROWTHBOOK.apiKeys[env]}`
  );
  const json: Features = await res.json();

  growthBook.setFeatures(json.features);
}

function getOrCreateUserId(): string | undefined {
  try {
    let value = localStorage.getItem(USER_ID_KEY);
    if (value == null) {
      value = nanoid();
      localStorage.setItem(USER_ID_KEY, value);
    }

    return value;
  } catch (error) {
    console.error(error);
    return;
  }
}
