import { useLocation, useMatches } from "@remix-run/react";
import type { RouteData } from "@remix-run/react/dist/routeData";
import type { AppData } from "@remix-run/server-runtime";
import type { Location, Params } from "react-router-dom";
import type { Thing, WithContext } from "schema-dts";

type HandleConventionArguments<Data extends AppData = AppData> = Readonly<{
  id: string;
  data: Data;
  params: Params;
  location: Location;
  parentsData: RouteData;
}>;

export type StructuredDatum<StructuredDataSchema extends Thing> =
  WithContext<StructuredDataSchema>;

export type HandleStructuredData<
  LoaderData extends AppData = AppData,
  StructuredDataSchema extends Thing = Thing
> = Readonly<{
  structuredData: StructuredDataFunction<LoaderData, StructuredDataSchema>;
}>;

function isHandleStructuredData(
  handle: unknown
): handle is HandleStructuredData {
  return (
    handle !== undefined &&
    (handle as HandleStructuredData).structuredData !== undefined &&
    typeof (handle as HandleStructuredData).structuredData === "function"
  );
}

export interface StructuredDataFunction<
  Data extends AppData = AppData,
  StructuredDataSchema extends Thing = Thing
> {
  (args: HandleConventionArguments<Data>):
    | StructuredDatum<StructuredDataSchema>
    | StructuredDatum<StructuredDataSchema>[]
    | null;
}

export function StructuredData() {
  const location = useLocation();
  const structuredData = useMatches().flatMap(
    ({ data, handle, id, params }, index, matches) => {
      if (isHandleStructuredData(handle)) {
        const result = handle.structuredData({
          id,
          data,
          params,
          location,
          parentsData: matches.slice(0, index).map(({ data }) => data),
        });
        if (result) return result;
      }

      return [];
    }
  );
  if (structuredData.length === 0) return null;

  const renderedScript = JSON.stringify(
    structuredData.length === 1 ? structuredData[0] : structuredData
  );
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: renderedScript,
      }}
    />
  );
}
